import * as React from 'react';
import styled from '@emotion/styled';

export const Insert = styled.section`
  font-size: 2.5rem;
  color: #3c434c;
  line-height: 4rem;
  font-weight: 800;

  text-decoration: underline;
  text-decoration-color: #f788dd;
  text-decoration-style: solid;

  margin-bottom: 1.666rem;

  padding-right: 2rem;

  em {
    font-family: Lora;
    font-weight: 400;
  }

  @media (max-width: 800px) {
    padding: 0;

    font-size: 1.5rem;
    line-height: 2.8rem;
  }
`;
