import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { TextBlock } from './Blocks';
import { Poster } from './Poster';

export function RecentProjects(props: {}) {
  const data = useStaticQuery(graphql`
    {
      allContentfulProjekt(
        sort: { fields: publishedAt, order: DESC }
        limit: 2
      ) {
        edges {
          node {
            title
            subtitle
            url
            preview {
              fluid(maxWidth: 540) {
                ...GatsbyContentfulFluid
              }
            }
            tags
          }
        }
      }
    }
  `);

  const projects = data.allContentfulProjekt.edges;

  return (
    <TextBlock style={{ marginTop: '4rem' }}>
      {projects.map((edge: any) => {
        return (
          <Link key={edge.node.url} to={`/projekte/${edge.node.url}`}>
            <Poster
              title={edge.node.title}
              subtitle={edge.node.subtitle}
              description={edge.node.tags.join(', ')}
              preview={edge.node.preview.fluid}
            />
          </Link>
        );
      })}
    </TextBlock>
  );
}
