import * as React from 'react';

import { ContentBlock, StageBlock, TextBlock } from '../components/Blocks';
import Layout from '../components/layout';
import { Insert } from '../components/Insert';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { RecentProjects } from '../components/RecentProjects';
import css from '@emotion/css';

const color = 'rgba(156, 236, 232, 0.5)';

export default ({ data }: any) => {
  return (
    <Layout>
      <StageBlock color={color}>
        <Insert
          css={css`
            @media (max-width: 800px) {
              padding: 2rem;
            }
          `}
        >
          <em>Unser Fokus</em> liegt auf den Themen Experiential Tourism, dem{' '}
          <em>Spannungsfeld Natur und Outdoor</em> sowie Nachhaltigkeit und
          Regionalität.
        </Insert>
      </StageBlock>

      <TextBlock>
        <p>
          <strong>Nix wird richtig gut</strong> ohne ein gescheites Fundament.
          Beim Marathon gewinnt nicht der, der am schnellsten losrennt, sondern
          der mit der besten Strategie. Und deshalb analysieren wir, machen ein
          Konzept und dann eine Strategie. Und dann die Technik und das Design.
          Und dabei sagt jeder was zum anderen. Zum Beispiel »Guten Morgen« oder
          etwas über seine Perspektive. Das nennen wir schubladenübergreifendes
          Denken und Arbeiten. Und natürlich höflich. Wir sind die Vielen: also
          multiprofessionell, also holistisch. Jahrelange Erfahrung in den
          Bereichen Journalismus, Geografie, Tourismusplanung, Programmierung,
          Kommunikationsdesign, dramaturgisch-künstlerischer Inszenierung und
          Handwerk verschmelzen zu Einem. Noch mal ganz seriös und sachlich: Von
          Analyse, Konzept und Strategie, dramaturgischer und gestalterischer
          Umsetzung bis hin zur (Web-)Technik können und wollen wir den ganzen
          Schaffensprozess abbilden und begleiten.
        </p>
      </TextBlock>

      <FeatureBlock color={color}>
        <h2 style={{ marginLeft: -34 }}>
          <Icon />
          Beratung <em>und</em> Strategie
        </h2>

        <p>
          Wir hören genau zu, selbst noch auf das zwischen den Zeilen Gesagte
          und verdichten es zu effektvollen Aussagen und Auftritten.
        </p>

        <BulletPoints>
          <li>Image- und Markenanalyse</li>
          <li>Markenentwicklung</li>
          <li>Strategische (Neu-)Positionierung</li>
          <li>Zielgruppenrelevante Kampagnen</li>
          <li>Konzeptentwicklung »Erlebnisraum«</li>
        </BulletPoints>
      </FeatureBlock>

      <FeatureBlock>
        <h2 style={{ marginLeft: -34 }}>
          <Icon />
          Ästhetik <em>und</em> Inhalt
        </h2>

        <p>
          Menschen bemalten Höhlen, behauten Marmor bis David da stand,
          verpacken den Reichstag. Schöner ist einfach schöner. Weil das
          Besondere und Kunstvolle etwas in uns anspricht.
        </p>

        <GatsbyImage
          style={{ maxWidth: 1055 }}
          fluid={data.file.childImageSharp.fluid}
        />
      </FeatureBlock>

      <FeatureBlock color={color}>
        <h2 style={{ marginLeft: -34 }}>
          <Icon />
          Webentwicklung
        </h2>

        <p>
          Bei uns beginnt Webentwicklung mit Stift und Papier. Bevor ein
          Programmierer loslegt, haben wir konzeptioniert bis die Köpfe rauchen
          und uns gezankt und jemand hat immer wieder gesagt: »Versteh’ ich noch
          nicht«. Bis alles ganz smooth ist.
        </p>

        <BulletPoints>
          <li>Prototyping</li>
          <li>UX-Design</li>
          <li>Responsive Design</li>
          <li>Backend, Frontend, CMS</li>
          <li>HTML 5, CSS3, Javascript</li>
          <li>SEO</li>
        </BulletPoints>
      </FeatureBlock>

      <RecentProjects />
    </Layout>
  );
};

const FeatureBlock = (props: any) => {
  return (
    <FeatureBlockStage color={props.color}>
      <FeatureBlockText>{props.children}</FeatureBlockText>
    </FeatureBlockStage>
  );
};

const FeatureBlockStage = styled.div<{ color?: string }>`
  padding: 2rem 0;
  background: ${props => (props.color ? props.color : undefined)};
`;

const FeatureBlockText = styled(TextBlock)`
  text-align: center;

  h2 svg {
    margin-right: 5px;
    margin-bottom: 20px;
  }

  h2 em {
    font-family: Lora;
    font-weight: 400;
  }
`;

const Icon = (props: any) => (
  <svg width={29} height={28} viewBox="0 0 39 38" {...props}>
    <path
      d="M38.208 14.41l-12.932 6.589 6.514 12.786-7.817 3.983-6.515-12.786-12.932 6.59-3.723-7.307 12.932-6.589L7.221 4.89 15.038.907l6.515 12.786 12.932-6.59z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

const BulletPoints = styled.ul`
  margin-left: 0;
  margin-right: 0;
  list-style: none;

  li {
    display: inline-block;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 600;
    margin-right: 1rem;
    margin-bottom: 0;

    ::before {
      content: '+ ';
      color: #47c0e8;
    }
  }
`;

export const query = graphql`
  query {
    file(relativePath: { eq: "illustration-leistungen.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1056) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
